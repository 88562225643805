
function App() {
  return (
    <div className='wrapper' >
		<header>
			<img src="/logo.png" alt="The World Learning Foundation" />
		</header>
		<main>
			<div className="flex" >
				<div className="main col" >
					<p>
						<b>The World Learning Foundation</b> fosters and promotes the health of the world's ecosystems by funding non-fiction film projects, clean-up activities directed to specific areas, and projects that draw attention to issues in the environment that can be improved with proper human interaction.
					</p>	
					<p>
						We espouse the scientific premise that humans and the earth can coexist, that we were made to be a part of the earth, and that we have a responsibility to care for her. Our goal at <b>The World Learning Foundation</b>
						<ul>
							<li>Offer grants and support to people making a difference in the world through  educational media and film.</li>
							<li>To provide funding for non-fiction film projects that draw attention to issues in the environment that could be remedied with directed human involvement.</li>
							<li>To provide educational materials in the form of complete presentation units for teachers, at no cost to them, that instruct students in the realities of science as it relates to the earth and its ability to fix itself, given time and the removal of the (toxin, problem, interruption)</li>
							<li>Provide funding for clean-up projects and media campaigns that will have an impact on a specific ecosystem.</li>
						</ul>
					</p>
				</div>
				<div className="video col" >
					<a href="https://www.youtube.com/watch?v=QLWTZLjwcLA" target="blank" ><img src="assets/video_preview.png" alt="Our first video" /></a> <br />
					<img className="watch_video_text" src="assets/watch_video_text.png" alt="Watch our first video" />
				</div>
			</div>
		</main>
		<footer>
			<ul>
				<li>
					<a href="https://www.youtube.com/@theworldlearningfoundation" target="blank" >
						<img src="/youtube.png" alt="Subscribe To Our YouTube" />
					</a>
				</li>
				<li>
					<a href="https://www.facebook.com/profile.php?id=100090740615109" target="blank" >
						<img src="/facebook.png" alt="Follow Us On Facebook" />
					</a>
				</li>
			</ul>
		</footer>
	</div>
  );
}

export default App;
